import React, { useState } from "react";
import Cookies from "universal-cookie";

export const cookies = new Cookies();

export const AppContext = React.createContext();

export function AppProvider({ children }) {
  const userCookie = cookies.get("ej-user");
  const [user, setUser] = useState(userCookie ? userCookie.split("-")[0] : null);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
