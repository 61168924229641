import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import debounce from "lodash/debounce";
import SmoothScroll from "smooth-scroll";
import Sticky from "react-sticky-el";
import "./js/utilities/focus";
import "./scss/app.scss";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Search from "./components/Search";
import SegmentDetails from "./components/SegmentDetails";
import SegmentThumbnails from "./components/SegmentThumbnails";
import FilterStats from "./components/FilterStats";
import FilterBar from "./components/FilterBar";
import CTA from "./components/CTA";
// import Modal from "./components/Modal";
import Login from "./components/Login";
import Footer from "./components/Footer";
import GDPRBanner from "./components/GDPRBanner";
import TOS from "./components/TOS";
import ModalLoginDetails from "./components/ModalLoginDetails";
import ModalContactDetails from "./components/ModalContactDetails";
import Loader from "./components/Loader";
import ReactGA from "react-ga4";
import { AppProvider, AppContext, cookies } from "./context/AppContext";

// ArcGIS is massive. Loading the map 5 seconds after page load helps improve the page speed
const WebMapView = React.lazy(() => new Promise((resolve) => setTimeout(() => resolve(import("././components/WebMapView")), 5000)));
const SegmentData = require("./data/result.json");

new SmoothScroll('a[href*="#"]');
const filteredPostalCount = 767473;

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

class App extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    ReactGA.initialize([{ trackingId: GA_MEASUREMENT_ID }]);

    this.state = {
      activeFilters: {
        income: "All",
        family: "All",
        segment: "All",
      },
      currentPostalCode: "",
      currentSegment: 1,
      errorMessage: "",
      filtersActive: false,
      filteredSegments: SegmentData,
      filteredPostalCount: filteredPostalCount,
      interstitial: false,
      multiSegments: [],
      mapPoints: [],
      mapLoading: true,
      noSegmentFilterResults: false,
      login: false,
      loginMessage: "",
      userIsLogged: cookies.get("ej-user") ? true : false,
      postalCounter: 0,
      postalLoading: false,
      ruralName: null,
      ruralSegment: null,
      segments: SegmentData,
      searchedSegment: "",
      showGDPR: true,
      showTOS: false,
      showLoginDetails: false,
      showContactDetails: false,
      tempPostalCode: "",
    };
    this.getSegmentDataPointsThrottled = debounce(this.getSegmentDataPoints, 300);
  }

  componentDidMount() {
    ReactGA.event("page_view", { custom_user_id: this.context.user });

    this.getSegmentData();
    this.getGDPRCookie();
    setTimeout(() => {
      this.scrollLinkIntoView();
    }, 1000);

    // Initialize Munchkin
    if (typeof window.Munchkin !== "undefined") {
      window.Munchkin.init("611-QKV-824");
    } else {
      console.warn("Munchkin failed to initialize.");
    }
  }

  // Scroll anchor links into view after page load
  scrollLinkIntoView() {
    var hash = window.location.hash ? window.location.hash.substring(1) : null;
    if (hash) {
      var element = document.getElementById(hash);
      if (element) {
        window.scroll({ top: element.offsetTop, behavior: "smooth" });
      }
    }
  }

  // Get initial app data
  getSegmentData() {
    this.getSegmentDataPoints(1);
  }

  // Get the points on the map associated with a segment
  getSegmentDataPoints = (id) => {
    this.setState({ mapLoading: true });
    const endpoint =
      this.state.currentPostalCode === ""
        ? `${process.env.REACT_APP_API_BASE}/api/segment/get_map_points?segment_id=${id}`
        : `${process.env.REACT_APP_API_BASE}/api/segment/get_map_points?segment_id=${id}&postal_code=${this.state.currentPostalCode.replace(/\s+/g, "")}`;
    axios.get(endpoint).then((response) => {
      if (response.status === 200) {
        this.setState({ mapPoints: response.data.data, mapLoading: false });
      }
    });
  };

  // Get the total number of postal codes represented by a segment
  getFilteredPostalCount = (segments) => {
    this.setState({ filteredPostalCount: null });
    var queryString = "";
    segments.forEach((segment) => {
      queryString += `segment_ids[]=${segment.id}&`;
    });
    axios.get(`${process.env.REACT_APP_API_BASE}/api/segment/get_pcode_count?${queryString}`).then((response) => {
      this.setState({ filteredPostalCount: response.data.data });
    });
  };

  // Set postal code in state and replace fourth digit with a space
  setCurrentPostalCode = (input) => {
    this.setState({
      tempPostalCode: input
        .replace(/(.{3})/, "$1 ")
        .replace("  ", " ")
        .trim()
        .toUpperCase(),
    });
  };

  handlePostalLookup = (position) => {
    this.setState(
      {
        currentPostalCode: this.state.tempPostalCode,
      },
      () => {
        this.getPostalData(position);
      },
    );
  };

  // Get the data associated with a postal code
  getPostalData = (position) => {
    let postalCode = this.state.currentPostalCode;

    if (postalCode === null || postalCode === "") {
      this.setState({ errorMessage: "Please enter a postal code." });
      return;
    }

    ReactGA.event("interaction", {
      custom_event_type: "Postal Code Lookup",
      custom_event_detail: position,
      custom_event_value: this.state.currentPostalCode,
      custom_user_id: this.context.user,
    });

    this.setState({
      postalLoading: true,
    });

    // Show the interstitial modal on the 5th lookup only
    if (this.state.postalCounter === 4) {
      this.setState({ interstitial: true });
    }

    // Get the postal code segments
    axios.get(`${process.env.REACT_APP_API_BASE}/api/pcode/get_segment?postal_code=${postalCode.replace(" ", "")}`).then((response) => {
      var status = response.data.result;
      if (status === "success") {
        let format = response.data.format;
        let data = response.data.data;
        this.clearFilters(true);
        if (format === "unique") {
          // If unique set the currentSegment
          this.setState(
            {
              errorMessage: null,
              multiSegments: [],
              currentSegment: data,
              searchedSegment: data,
              filtersActive: false,
              postalLoading: false,
              ruralName: null,
              ruralSegment: null,
              postalCounter: this.state.postalCounter + 1,
            },
            () => this.getSegmentDataPointsThrottled(data),
          );
        } else if (format === "multi") {
          // If multiple display the multiSegment UI
          this.setState({
            errorMessage: null,
            multiSegments: data,
            filtersActive: false,
            postalLoading: false,
          });
          document.getElementById("explore").scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      if (status === "error") {
        // Handle errors
        let format = response.data.format;
        let data = response.data.data;
        if (format === "non_residential_zoning" || format === "invalid_pcode") {
          this.setState({
            errorMessage: data,
            postalLoading: false,
          });
        }
      }
    });
  };

  // Toggle the login modal state
  showLogin = () => {
    this.setState({ login: true });

    ReactGA.event("interaction", {
      custom_event_type: "Login Click",
      custom_event_detail: "Show Login",
      custom_user_id: this.context.user,
    });
  };

  // Check if password matches and set cookie on success
  handleLogin = ({ username, password }) => {
    const apiEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;

    axios
      .post(apiEndpoint, { username, password })
      .then((response) => {
        if (response.status === 200 && response.data.username && response.data.timestamp) {
          const timestamp = Date.parse(response.data.timestamp);
          const user = `${response.data.username}-${timestamp}`;

          // Cookie expires in 1 year
          let date = new Date();
          date.setDate(date.getDate() + 365);
          cookies.set("ej-user", user, { expires: date });
          // Set state and remove error message
          loginSuccess();
        } else {
          // Show error message
          loginError();
        }
      })
      .catch((error) => {
        console.log(error);
        // Show error message
        loginError();
      });

    const loginSuccess = () => {
      this.setState({ loginMessage: "", userIsLogged: true });
    };

    const loginError = () => {
      this.setState({ loginMessage: "Incorrect username or password. Please try again." });
    };
  };

  // Toggle the filtersActive state to hide or show the filter bar
  toggleFiltersActive = () => {
    this.setState({ filtersActive: !this.state.filtersActive });
  };

  // Set the current segment
  setCurrentSegment = (id) => {
    // If clicked active segment no updates required, but if the number of filteredSegments is one we should be able to make a request,
    // because this is a bug when one filteredSegment is not clickable.
    // By default if there is only one segment in filteredSegments it became an active, then we should be able to make an request manually,
    // because request isn't triggered at that point
    if (id === this.state.currentSegment && this.state.filteredSegments.length > 1) {
      return;
    }
    // Set the currentSegment state and increment segmentCounter to determine limits and then get the current segment map points
    this.setState({ currentSegment: id }, () => this.getSegmentDataPointsThrottled(id));
  };

  // Set multiSegments state when a postal code is multiple
  setMultiSegment = (id, name) => {
    this.setState({ multiSegments: [], ruralSegment: id, ruralName: name });
    this.setCurrentSegment(id);
    document.getElementById("segment-results").scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // Filter the segments based on active filters
  setFilteredSegments = () => {
    var filteredSegments = [...this.state.segments];

    // Loop through active filters and remove any that do not match filter criteria
    Object.keys(this.state.activeFilters).forEach((key) => {
      var filterCondition = this.state.activeFilters[key];
      if (key === "family" && this.state.activeFilters[key] !== "All") {
        filteredSegments = filteredSegments.filter((el) => el["filters"]["family_life"] === filterCondition);
      }

      if (key === "income" && this.state.activeFilters[key] !== "All") {
        var operation = filterCondition.charAt(0);
        filterCondition = filterCondition.substr(1);
        // Handle an income range
        var rangeStart, rangeEnd;
        // Range is delinated by range low | range high
        if (filterCondition.includes("|")) {
          var range = filterCondition.split("|");
          rangeStart = range[0];
          rangeEnd = range[1];
        }
        // Filter by less then value
        if (operation === "<") {
          filteredSegments = filteredSegments.filter((el) => el["filters"]["income"] < filterCondition);
          // FIlter by greater then value
        } else if (operation === ">") {
          filteredSegments = filteredSegments.filter((el) => el["filters"]["income"] > filterCondition);
          // Filter by range
        } else if (operation === "~") {
          filteredSegments = filteredSegments.filter((el) => el["filters"]["income"] > rangeStart && el["filters"]["income"] < rangeEnd);
        }
      }

      if (key === "segment" && this.state.activeFilters[key] !== "All") {
        if (filterCondition === "Ideal") {
          filteredSegments = filteredSegments.filter((el) => el["featured"] === true);
        }
        if (filterCondition === "Other") {
          filteredSegments = filteredSegments.filter((el) => el["featured"] === false || el["featured"] === undefined);
        }
      }
    });

    // Reset if both filters are not active
    if (this.state.activeFilters.income === "All" && this.state.activeFilters.family === "All" && this.state.activeFilters.segment === "All") {
      this.setState(
        {
          activeFilters: {
            income: "All",
            family: "All",
            segment: "All",
          },
          filteredSegments: [],
          currentSegment: 1,
          filteredPostalCount: filteredPostalCount,
          noSegmentFilterResults: false,
          ruralName: null,
        },
        () => {
          this.setSegments(filteredSegments);
        },
      );
    } else {
      ReactGA.event("interaction", {
        custom_event_type: "Filter Click",
        custom_event_detail: "Set Filter",
        custom_event_value: JSON.stringify(this.state.activeFilters),
        custom_user_id: this.context.user,
      });

      if (filteredSegments.length >= 1) {
        // Otherwise set currentSegment state and center the array
        this.setState(
          {
            filteredSegments: [],
            currentSegment: filteredSegments[0]["id"],
            noSegmentFilterResults: false,
            currentPostalCode: "",
            ruralName: null,
          },
          () => {
            this.setSegments(filteredSegments);
            this.getFilteredPostalCount(filteredSegments);
          },
        );
        // TODO: Potential improvement
        // Trigger map update every time filters are changed
        // Code below works only for the last one item
        // if (filteredSegments.length === 1) {
        //   this.setState(() => { this.getSegmentDataPointsThrottled(filteredSegments[0]["id"]) });
        // }
      } else {
        this.setState({
          currentPostalCode: "",
          noSegmentFilterResults: true,
          ruralName: null,
        });
      }
    }
  };

  // Set the activeFilters state
  updateFilters = (filters) => {
    this.setState(
      {
        activeFilters: filters,
      },
      () => {
        this.setFilteredSegments();
      },
    );
  };

  // Clear all active filters
  clearFilters = (ignoreEvent) => {
    var noEvent = ignoreEvent || false;
    this.setState({
      activeFilters: {
        income: "All",
        family: "All",
        segment: "All",
      },
      filtersActive: false,
      filteredSegments: [...this.state.segments],
      noSegmentFilterResults: false,
    });
    if (!noEvent) {
      ReactGA.event("interaction", {
        custom_event_type: "Filter Click",
        custom_event_detail: "Clear Filters",
        custom_user_id: this.context.user,
      });
    }
  };

  setSegments(originalArray) {
    var array = [...originalArray];
    this.setState({ filteredSegments: array });
  }

  // Close the active modal
  closeModal = (target) => {
    this.setState({
      [target]: false,
    });
  };

  setGDPRCookie = (accepted) => {
    if (accepted) {
      let date = new Date();
      // Cookie expires in 1 year
      date.setDate(date.getDate() + 365);
      cookies.set("sf-tracking-consent", "true", { expires: date });
      // cookies.set("sf-tracking-consent", "true", { expires: date, domain: '.environicsanalytics.com' });
    }

    this.setState({ showGDPR: false });
  };

  getGDPRCookie = () => {
    var gdprCookie = cookies.get("sf-tracking-consent");

    if (gdprCookie === "true") {
      this.setState({ showGDPR: false });
    }
  };

  toggleTOS = () => {
    this.setState({ showTOS: !this.state.showTOS });
  };

  toggleLoginDetails = () => {
    this.setState({ showLoginDetails: !this.state.showLoginDetails });
  };

  toggleContactDetails = () => {
    this.setState({ showContactDetails: !this.state.showContactDetails });
  };

  isMobile() {
    if (window.innerWidth < 900) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        {!this.state.userIsLogged ? (
          <Login close={this.closeModal} handleLogin={this.handleLogin} message={this.state.loginMessage} />
        ) : (
          <>
            {this.state.showGDPR ? <GDPRBanner setGDPRCookie={this.setGDPRCookie} /> : null}
            <a className="skip-to-main" href="#main">
              Skip to main content
            </a>
            <main>
              <Header showLoginDetails={this.toggleLoginDetails} showContactDetails={this.toggleContactDetails} />
              <Hero
                segments={SegmentData}
                onClick={this.setCurrentSegment}
                handlePostalLookup={this.handlePostalLookup}
                multiSegments={this.state.multiSegments}
                setMultiSegment={this.setMultiSegment}
                currentPostalCode={this.state.tempPostalCode}
                handleChange={this.setCurrentPostalCode}
                postalLoading={this.state.postalLoading}
                errorMessage={this.state.errorMessage}
              />
              <section id="explore" className="explore">
                <Search
                  currentPostalCode={this.state.tempPostalCode}
                  handlePostalLookup={this.handlePostalLookup}
                  onClick={this.setCurrentSegment}
                  errorMessage={this.state.errorMessage}
                  multiSegments={this.state.multiSegments}
                  setMultiSegment={this.setMultiSegment}
                  handleChange={this.setCurrentPostalCode}
                  postalLoading={this.state.postalLoading}
                />
                <Sticky id="segment-results" className="sticky-filters" boundaryElement="#explore" disabled={this.isMobile()} holderCmp="section" positionRecheckInterval={100}>
                  <FilterStats
                    segments={this.state.filteredSegments}
                    filtersActive={this.state.filtersActive}
                    onClick={this.toggleFiltersActive}
                    mapPoints={this.state.mapPoints}
                    filteredPostalCount={this.state.filteredPostalCount}
                  />
                  {this.state.filtersActive ? (
                    <FilterBar updateFilters={this.updateFilters} segments={this.state.segments} hasResults={this.state.noSegmentFilterResults} clearFilters={this.clearFilters} />
                  ) : null}
                </Sticky>
                <section className={this.state.noSegmentFilterResults ? "util__disabled" : null}>
                  <SegmentThumbnails segments={this.state.filteredSegments} currentSegment={this.state.currentSegment} onClick={this.setCurrentSegment} />
                  <Suspense
                    fallback={
                      <div className="map__container map__container--loading">
                        <Loader />
                      </div>
                    }
                  >
                    <WebMapView mapPoints={this.state.mapPoints} currentPostalCode={this.state.currentPostalCode} mapLoading={this.state.mapLoading} />
                  </Suspense>
                  <div className="map__instructions">Use two fingers to pan and zoom</div>
                  <SegmentDetails
                    segments={this.state.filteredSegments}
                    currentSegment={this.state.currentSegment}
                    searchedSegment={this.state.searchedSegment}
                    currentPostalCode={this.state.currentPostalCode}
                    onClick={this.setCurrentSegment}
                    ruralName={this.state.ruralName}
                    ruralSegment={this.state.ruralSegment}
                  />
                </section>
              </section>
              {/* {this.state.interstitial ? <Modal type="interstitial" close={this.closeModal} /> : null} */}
              <CTA type="questions" showContactDetails={this.toggleContactDetails} />
              <Footer showTOS={this.toggleTOS} />
              {this.state.showTOS ? <TOS closeTOS={this.toggleTOS} /> : null}
              {this.state.showLoginDetails ? <ModalLoginDetails closeLoginDetails={this.toggleLoginDetails} /> : null}
              {this.state.showContactDetails ? <ModalContactDetails closeContactDetails={this.toggleContactDetails} /> : null}
            </main>
          </>
        )}
      </>
    );
  }
}

// Wrap the existing ReactDOM.render with the Provider
ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root"),
);
